import { useEffect } from 'react';
import PropTypes from 'prop-types';

const IndexPage = ({ location }) => {
  useEffect(() => {
    window.location = `${process.env.LANDING_PAGE_URL}/${location.search}`;
  }, []);

  return null;
};
IndexPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default IndexPage;
